<template>
    <div class="float">

        <!-- Disini Menu Upload
            {{ $globalData.data.pasfoto }} <br /> -->
        <!-- ini IMG
            <b-img :src="`https://newpsb.baiturrahman.com/php.script/${this.$globalData.data.pasfoto}`" width="145px"
                class="ml-1" />
            INI PDF
            <embed :src="`https://newpsb.baiturrahman.com/php.script/${this.$globalData.data.pasfoto}`" width="50%"
                height="50%" type="application/pdf" />
            ini mbed JPG -->
        <div class="row">
            <div class="col-1 gradient-background">

            </div>

            <div class="col col-md-6">
                <input type="file" style="display: none" ref="fileInput" @change="handleFileUpload" />
                <b-card header="PasFoto" header-tag="header" footer-tag="footer" class="mb-2">
                    <embed :src="`https://newpsb.baiturrahman.com/php.script/${this.$globalData.data.pasfoto}`"
                        width="50%" height="auto" :type="getFileType($globalData.data.pasfoto)" />
                    <b-link class="ml-2" @click="upload('pasfoto')">Uload</b-link>
                </b-card>
                <b-card header="Kartu Keluarga" header-tag="header" footer-tag="footer" class="mb-2">
                    <embed :src="`https://newpsb.baiturrahman.com/php.script/${this.$globalData.data.kk}`" width="50%"
                        height="auto" :type="getFileType($globalData.data.kk)" />
                    <b-link class="ml-2">Uload</b-link>
                </b-card>
                <b-card header="Akte Kelahiran" header-tag="header" footer-tag="footer" class="mb-2">
                    <embed :src="`https://newpsb.baiturrahman.com/php.script/${this.$globalData.data.akte}`" width="50%"
                        height="auto" :type="getFileType($globalData.data.akte)" />
                </b-card>
                <b-card header="KTP Ayah" header-tag="header" footer-tag="footer" class="mb-2">
                    <embed :src="`https://newpsb.baiturrahman.com/php.script/${this.$globalData.data.ktpa}`" width="50%"
                        height="auto" :type="getFileType($globalData.data.ktpa)" />
                </b-card>
                <b-card header="KTP Ibu" header-tag="header" footer-tag="footer" class="mb-2">
                    <embed :src="`https://newpsb.baiturrahman.com/php.script/${this.$globalData.data.ktpi}`" width="50%"
                        height="auto" :type="getFileType($globalData.data.ktpi)" />
                </b-card>

                <br />
                <br />
                <br />
                <br />
                <br />
            </div>

        </div>


    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'Upload',

    components: {
    },
    data() {
        return {
            jenisupload: '',
        }
    },
    computed: {
        fileType() {
            const file = this.$globalData.data.pasfoto;
            if (file.endsWith('.pdf')) {
                return 'application/pdf';
            } else if (file.endsWith('.jpg') || file.endsWith('.jpeg')) {
                return 'image/jpeg';
            } else if (file.endsWith('.png')) {
                return 'image/png';
            }
            // Default type jika format tidak dikenal
            return 'application/octet-stream';
        },
    },
    mounted() {
        console.log("gambar 1", `https://newpsb.baiturrahman.com/php.script/${this.$globalData.data.pasfoto}`);

    },
    methods: {
        getFileType(file) {
            if (file == null) {
                return "image/jpeg"
            }
            if (file.endsWith('.pdf')) {
                return 'application/pdf';
            } else if (file.endsWith('.jpg') || file.endsWith('.jpeg')) {
                return 'image/jpeg';
            } else if (file.endsWith('.png')) {
                return 'image/png';
            }
            // Default type jika format tidak dikenal
            return 'application/octet-stream';
        },

        upload(para) {
            console.log("upload para ", para)
            this.jenisupload = para
            this.$refs.fileInput.click();
        },
        handleFileUpload(event) {
            const uploadedFile = event.target.files[0];
            // Gunakan URL.createObjectURL() untuk menampilkan gambar yang dipilih oleh pengguna secara langsung
            this.profileImage = URL.createObjectURL(uploadedFile);
            // console.log("file Name", uploadedFile);
            this.uploadfile(uploadedFile, this.jenisupload);
        },
        uploadfile(nf, jenisupload) {
            // console.log("uploadfile", nf, th, index);
            //   this.valuedownload = 0;
            var config = {
                onUploadProgress: (progressEvent) => {
                    // ini harus menggunakan arrow function
                    console.log("progress event", progressEvent);
                    var percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    this.valuedownload = percentCompleted;
                    // console.log("progress", percentCompleted, this.valuedownload);
                },
                headers: {
                    "Content-Type": "multipart/header-data",
                },
            };
            var data = new FormData();
            data.append("userid", this.$globalData.UserId);
            data.append("file", nf);
            data.append('jenisdata', jenisupload);
            axios
                .post(
                    this.$appUrl + "uploadprofile.php?token=" + jenisupload,
                    data,
                    config
                )
                .then((res) => {
                    //  th.target.innerText = res.data.status;
                    this.dok = null;
                    this.$toast.success(res.data.status, {
                        type: "success",
                        position: "top-right",
                        duration: 3000,
                        dismissible: true,
                    }); //   output.className = 'container';
                    //   output.innerHTML = res.data;
                    let tg = new Date();
                    this.$globalData.data.pasfoto =
                        res.data.namafile + "?d=" + tg;
                    console.log("data image baru", this.$globalData.data.pasfoto)
                })
                .catch(function (err) {
                    console.log("error", err);
                    //   output.className = 'container text-danger';
                    //   output.innerHTML = err.message;
                });
        },
    }

}
</script>