<template>
    <div class="float-container">

        <div class="border-bottom">
            <b>PENDAFTARAN {{
                $globalData.data.jenjang ==
                    'P' ? 'SMP' :
                    'SMA' }}{{ ' Gel ' +
                    $globalData.data.gelombang }}</b> <br />{{ $globalData.data.nama }}
        </div>
        <!-- < h2> Menu Driver { { $globalData.data.gelombang }}</h2> -->

        <Profile v-show="$globalData.SelectedMenu == '0'" />
        <Ortu v-show="$globalData.SelectedMenu == '1'" />
        <Info v-show="$globalData.SelectedMenu == '2'" />
        <Upload v-show="$globalData.SelectedMenu == '3'" />
        <Kartu v-show="$globalData.SelectedMenu == '4'" />
    </div>

</template>

<script>
import Profile from '../views/profile'
import Info from '../views/info'
import Upload from '../views/upload'
import Kartu from '../views/kartu'
import Ortu from '../views/ortu'
import axios from "axios";
export default {
    name: 'Menu',

    components: {
        Profile, Info, Upload, Kartu, Ortu
    },
    data() {
        return {
            para: this.$route.params.id,
        }
    },
    // mounted() {
    mounted: async function () {
        const isAuthenticated = localStorage.getItem('authToken');
        if (this.para != isAuthenticated) {
            this.$router.push({ path: "/" })
            console.log('NOtvalid')
        } else {
            this.$globalData.TokenOK = 1
            //  $globalData.UserId
            this.$globalData.UserId = localStorage.getItem('userid')
            console.log("userid", this.$globalData.UserId)
        }
        if (Object.keys(this.$globalData.data).length === 0) {
            // alert("Objek data kosong");
            await axios
                .get(this.$appUrl + "getdata.php?login=" + this.$globalData.UserId)
                .then((res) => {
                    this.$globalData.data = res.data[0];
                    this.$globalData.databackup = JSON.parse(JSON.stringify(res.data[0]));
                })
            console.log("finished data")
        }
    }
}
</script>
