<template>
    <div class="float">

        <div class="row">
            <div class="col-1 gradient-background">
            </div>
            <div class="col col-md-6">
                <b-form-input size="sm" value="Data Orang Tua" class="mb-2 bold-input text-center" disabled />
                Nama Ayah:
                <b-form-input size="sm" v-model="$globalData.data.namaayah" class="mb-2 bold-input" />
                Jenis Ayah:
                <b-form-radio-group v-model="$globalData.data.jenisayah" class="mb-2"
                    :options="[{ text: 'Kandung', value: 'kandung' }, { text: 'Wali', value: 'wali' }]"></b-form-radio-group>
                Alamat:
                <b-form-textarea size="sm" v-model="$globalData.data.alamatayah" class="mb-2 bold-input" />
                Kota:
                <b-form-input size="sm" v-model="$globalData.data.kotaayah" class="mb-2 bold-input" />
                Tempat Lahir Ayah:
                <b-form-input size="sm" v-model="$globalData.data.tmplahirayah" class="mb-2 bold-input" />
                Tgl Lahir Ayah:
                <b-form-input size="sm" v-model="$globalData.data.tgllahirayah" type="date" class="mb-2 bold-input" />
                Pendidikan Ayah:
                <b-form-select v-model="$globalData.data.pendidikanayah" class="mb-2 bold-input" size="sm"
                    :options="pendidikan"></b-form-select>

                Pekerjaan Ayah:
                <b-form-select v-model="$globalData.data.pekerjaanayah" class="mb-2 bold-input" size="sm"
                    :options="pekerjaan"></b-form-select>
                Penghasilan Ayah:
                <b-form-select v-model="$globalData.data.penghasilanayah" class="mb-2 bold-input" size="sm"
                    :options="penghasilan"></b-form-select>
                <hr />
                Nama Ibu:
                <b-form-input size="sm" v-model="$globalData.data.namaibu" class="mb-2 bold-input" />
                Jenis Ibu:
                <b-form-radio-group v-model="$globalData.data.jenisibu" class="mb-2 "
                    :options="[{ text: 'Kandung', value: 'kandung' }, { text: 'Wali', value: 'wali' }]"></b-form-radio-group>
                Pendidikan Ibu:
                <b-form-select v-model="$globalData.data.pendidikanibu" class="mb-2 bold-input" size="sm"
                    :options="pendidikan"></b-form-select>

                Pekerjaan Ibu:
                <b-form-select v-model="$globalData.data.pekerjaanibu" class="mb-2 bold-input" size="sm"
                    :options="pekerjaan"></b-form-select>
                Penghasilan Ibu:
                <b-form-select v-model="$globalData.data.penghasilanibu" class="mb-2 bold-input" size="sm"
                    :options="penghasilan"></b-form-select>

                Tempat Lahir Ibu:
                <b-form-input size="sm" v-model="$globalData.data.tmplahiribu" class="mb-2 bold-input" />
                Tgl Lahir Ibu:
                <b-form-input size="sm" v-model="$globalData.data.tgllahiribu" type="date" class="mb-2 bold-input" />
                <hr />
                <b-button size="sm" class="mr-2" @click="saveprofile">Update</b-button>
                <b-button size="sm" @click="cancelprofile">Cancel</b-button>
                <br />
                <br />
                <br />
                <br />
                <br />

            </div>
        </div>
    </div>
</template>
<!-- {
    "identify": 59,
    "nama": "yoyo abi balqis",
    "tempatlahir": "bandung",
    "tgllahir": "2006-03-04",
    "nisn": 101,
    "nik": 101,
    "jeniskel": "L",
    "alamat": "Mutiara Depok B12",
    "nohp": 8151633007,
    "asalsekolah": "SD Binakhair",
    "pilihanjurusan": "",
    "warganegara": "wni",
    "tinggi": 165,
    "berat": 56,
    "anakke": 2,
    "dari": 4,
    "jenisayah": "",
    "namaayah": "",
    "tmplahirayah": "",
    "tgllahirayah": "",
    "pekerjaanayah": "",
    "penghasilanayah": "",
    "pendidikanayah": "",
    "alamatayah": "",
    "jenisibu": "",
    "namaibu": "",
    "tmplahiribu": "",
    "tgllahiribu": "",
    "pekerjaanibu": "",
    "penghasilanibu": "",
    "pendidikanibu": "",
    "informasi": "c",
    "motivasi": "d",
    "userid": "yoyo",
    "password": 123,
    "status": 1,
    "jenjang": "P",
    "kota": "Depok",
    "kotaayah": null,
    "nova": 7216240225261003,
    "pasfoto": "uploads/pasfoto-yoyo.jpg",
    "kk": null,
    "akte": null,
    "ktpa": null,
    "ktpi": null,
    "ketsehat": null,
    "isalumni": 0,
    "gelombang": 1
  } -->
<script>

export default {
    name: 'Ortu',

    components: {

    },
    data() {
        return {
            pekerjaan: [{ text: '1. PNS/ASN', value: '1' },
            { text: '2. TNI/Polsi', value: '2' },
            { text: '3. Pegawai Swasta', value: '3' },
            { text: '4. Wiraswasta', value: '4' },
            { text: '5. Pegawai BUMN', value: '5' },
            { text: '6. Pensiunan', value: '6' },
            { text: '7. Lain-lain', value: '7' }
            ],
            penghasilan: [{ text: '1. < 2.5Jt', value: '1' },
            { text: '2. < 5Jt', value: '2' },
            { text: '3. < 7.5Jt', value: '3' },
            { text: '4. < 10Jt', value: '4' },
            { text: '5. >= 10Jt', value: '5' },
            ],
            pendidikan: [{ text: '1. SD/MI', value: '1' },
            { text: '2. SMP/MTs', value: '2' },
            { text: '3. SMA Sederajat', value: '3' },
            { text: '4. D3', value: '4' },
            { text: '5. D4', value: '5' },
            { text: '7. S1', value: '6' },
            { text: '8. S2', value: '7' },
            { text: '9. S3', value: '8' },

            ]
        }
    },
    methods: {
        saveprofile() {
            console.log('data', this.$globalData.data);
            alert(JSON.stringify(this.$globalData.data))
        },
        cancelprofile() {
            // const x = this.$globalData.databackup
            this.$globalData.data = JSON.parse(JSON.stringify(this.$globalData.databackup))
            console.log("bakcup", this.$globalData.databackup)
        }
    }
}
</script>
<style>
.bold-input {
    font-weight: bold !important;
}
</style>