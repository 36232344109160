<template>
    <div class="float">
        Disini Menu Info
    </div>
</template>

<script>

export default {
    name: 'Info',

    components: {

    },
}
</script>