<template>
    <div class="float">
        Disini Menu Kartu
    </div>
</template>

<script>

export default {
    name: 'Kartu',

    components: {

    },
}
</script>