<template>
    <div class="float">
        <div class="row">
            <div class="col-1 gradient-background">

            </div>
            <div class="col col-md-6">
                <b-form-input size="sm" value="Data Profile" class="mb-2 bold-input text-center" disabled />
                Nama:
                <b-form-input size="sm" v-model="$globalData.data.nama" class="mb-2 bold-input" />
                Jenis Kelamin:
                <b-form-radio-group v-model="$globalData.data.jeniskel" class="mb-2 "
                    :options="[{ text: 'Laki-laki', value: 'L' }, { text: 'Perempuan', value: 'P' }]"></b-form-radio-group>
                NIS:
                <b-form-input size="sm" v-model="$globalData.data.nis" class="mb-2 bold-input" />
                NiK:
                <b-form-input size="sm" v-model="$globalData.data.nik" class="mb-2 bold-input" />
                Tempat Lahir:
                <b-form-input size="sm" v-model="$globalData.data.tempatlahir" class="mb-2 bold-input" />
                Tgl Lahir:
                <b-form-input size="sm" v-model="$globalData.data.tgllahir" type="date" class="mb-2 bold-input" />
                Alamat:
                <b-form-textarea size="sm" v-model="$globalData.data.alamat" class="mb-2 bold-input" />
                Kota:
                <b-form-input size="sm" v-model="$globalData.data.kota" class="mb-2 bold-input" />
                {{ $globalData.databackup.kota }}
                Warga Negara:
                <b-form-radio-group v-model="$globalData.data.warganegara" class="mb-2 "
                    :options="[{ text: 'WNI', value: 'wni' }, { text: 'WNA', value: 'wna' }]"></b-form-radio-group>
                No HP:
                <b-form-input size="sm" v-model="$globalData.data.nohp" class="mb-2 bold-input" />
                Tinggi Badan (cm):
                <b-form-input size="sm" v-model="$globalData.data.tinggi" class="mb-2 bold-input" type="number" />
                Berat Badan (kg):
                <b-form-input size="sm" v-model="$globalData.data.berat" class="mb-2 bold-input" type="number" />
                Anak Ke:
                <b-input-group size="sm" class="mb-2 bold-input">
                    <b-form-input v-model="$globalData.data.anakke" type="number" class="bold-input" />
                    <b-form-input value="Dari:" class="col-2" disabled></b-form-input>
                    <b-form-input v-model="$globalData.data.dari" type="number" class="bold-input" />
                </b-input-group>
                Mengetahui Informasi dari:
                <b-form-radio-group v-model="$globalData.data.informasi" class="mb-2" stacked
                    :options="informasi"></b-form-radio-group>
                Masuk Baiturrahman Karena:
                <b-form-radio-group v-model="$globalData.data.motivasi" class="mb-2" stacked
                    :options="alasan"></b-form-radio-group>

                <hr />
                <b-button size="sm" class="mr-2" @click="saveprofile">Update</b-button>
                <b-button size="sm" @click="cancelprofile">Cancel</b-button>
                <br />
                <br />
                <br />
                <br />
                <br />

            </div>
        </div>

    </div>
</template>
<!-- {
    "identify": 59,
    "nama": "yoyo abi balqis",
    "tempatlahir": "bandung",
    "tgllahir": "2006-03-04",
    "nisn": 101,
    "nik": 101,
    "jeniskel": "L",
    "alamat": "Mutiara Depok B12",
    "nohp": 8151633007,
    "asalsekolah": "SD Binakhair",
    "pilihanjurusan": "",
    "warganegara": "wni",
    "tinggi": 165,
    "berat": 56,
    "anakke": 2,
    "dari": 4,

    "jenisayah": "",
    "namaayah": "",
    "tmplahirayah": "",
    "tgllahirayah": "",
    "pekerjaanayah": "",
    "penghasilanayah": "",
    "pendidikanayah": "",
    "alamatayah": "",

    "jenisibu": "",
    "namaibu": "",
    "tmplahiribu": "",
    "tgllahiribu": "",
    "pekerjaanibu": "",
    "penghasilanibu": "",
    "pendidikanibu": "",

    "informasi": "c",
    "motivasi": "d",
    "userid": "yoyo",
    "password": 123,
    "status": 1,
    "jenjang": "P",
    "kota": "Depok",
    "kotaayah": null,
    "nova": 7216240225261003,
    "pasfoto": "uploads/pasfoto-yoyo.jpg",
    "kk": null,
    "akte": null,
    "ktpa": null,
    "ktpi": null,
    "ketsehat": null,
    "isalumni": 0,
    "gelombang": 1
  } -->

<script>

export default {
    name: 'Profile',

    components: {

    },
    data() {
        return {
            informasi: [{ text: 'a. Website Baiturrahman', value: 'a' },
            { text: 'b. Media Sosial', value: 'b' },
            { text: 'c. Alumni Baiturrahman', value: 'c' },
            { text: 'd. Saudara/Teman', value: 'd' },
            { text: 'e. Media Massa (Radio, Koran, dll)', value: 'e' },
            { text: 'f. Spanduk/Brosur', value: 'f' },
            { text: 'g. Road Show/ Presentasi Panitia', value: 'g' },
            { text: 'h. Lain-lain', value: 'h' }
            ],
            alasan: [{ text: 'a. Kemauan Sendiri', value: 'a' },
            { text: 'b. Kemauan Orangtua', value: 'b' },
            { text: 'c. Kemauan Sendiri dan Orangtua', value: 'c' },
            { text: 'd. Mengikuti Teman', value: 'd' },
            { text: 'e. Coba-coba', value: 'e' },
            ],
        }
    },
    methods: {
        saveprofile() {
            console.log('data', this.$globalData.data);
            alert(JSON.stringify(this.$globalData.data))
        },
        cancelprofile() {
            // const x = this.$globalData.databackup
            this.$globalData.data = JSON.parse(JSON.stringify(this.$globalData.databackup))
            console.log("bakcup", this.$globalData.databackup)
        }
    }
}
</script>
<style>
.gradient-background {
    height: auto;
    /* Atur tinggi sesuai kebutuhan */
    background: linear-gradient(to left, white, green);
    /* background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(9, 121, 24, 0.8464811950170693) 12%, rgba(0, 212, 255, 1) 100%); */
    /* Ganti dengan warna gradasi yang Anda inginkan */
}

.bold-input {
    font-weight: bold !important;
}
</style>