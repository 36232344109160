<template>
    <div id="app">

        <!-- <Navbarform v-show="false" /> -->
        <div :class="$globalData.TokenOK == 1 ? 'container-fluid' : 'fluid'" v-show="true">
            <div :class="$globalData.TokenOK == 1 ? 'fixed-top bg-white text-left' : 'text-center'">

                <img src="./assets/logo-1.jpg" width="100%" height="60px" bg-white />

                <div style="position: absolute; margin-top: -55px">
                    <div class="row ">
                        <div class="col">
                            <img src="https://newpsb.baiturrahman.com/img/logo-psb.ebbfb8de.jpg" width="45px"
                                class="ml-1" />
                        </div>
                        <div class="col pl-0">

                            <div class="social-media-links" style="font-size: 12px; margin-top: 15px;">
                                <a href="https://www.youtube.com/@pesantrenbaiturrahman" target="_blank"
                                    aria-label="YouTube">
                                    <i class="bi bi-youtube"></i>
                                    <!-- <span>YouTube</span> -->
                                </a>
                                <a href="https://baiturrahman.com/" target="_blank" aria-label="Website">
                                    <i class="bi bi-globe"></i>
                                    <!-- <span>Website</span> -->
                                </a>
                                <a href="https://www.instagram.com/ponpesbaiturrahman/" target="_blank"
                                    aria-label="Instagram">
                                    <i class="bi bi-instagram"></i>
                                    <!-- <span>Instagram</span> -->
                                </a>
                                <a href="https://www.facebook.com/officialbaiturrahman" target="_blank"
                                    aria-label="Facebook">
                                    <i class="bi bi-facebook"></i>
                                    <!-- <span>Facebook</span> -->
                                </a>
                                <a href="https://wa.link/scp19c" target="_blank" aria-label="WhatsApp">
                                    <i class="bi bi-whatsapp"></i>
                                    <!-- <span>WhatsApp</span> -->
                                </a>
                                <a href="https://www.tiktok.com/@ponpesbaiturrahman" target="_blank"
                                    aria-label="TikTok">
                                    <i class="bi bi-tiktok"></i>
                                    <!-- <span>TikTok</span> -->
                                </a>
                            </div>
                        </div>
                    </div>



                </div>



                <!-- {{ $globalData.TokenOK }} -->
                <div class="div  mt-0 mr-1 float-right" v-show="$globalData.TokenOK == 1">
                    <span style="font-size: 12px;">Userid:{{ $globalData.UserId }}
                    </span>
                    <i class="bi bi-box-arrow-right" @click="logout"></i>
                </div>
                <!-- <hr v-show="$globalData.TokenOK == 1" /> -->
            </div>
            <div>
                <router-view />
            </div>
            <div class="menu-fixed-bottom" v-show="$globalData.TokenOK == '1'">
                <!-- MENU {{ selectedMenu }} -->
                <div class="menu-item" v-for="(menu, index) in menus" :key="menu.name"
                    :class="{ active: selectedMenu === index }" @click="selectMenu(index)">
                    <i :class="menu.icon" class="menu-icon"></i>
                    <p>{{ menu.name }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Footer from "./components/footer.vue";
export default {
    name: "App",
    components: {

    },
    data() {
        return {
            menus: [
                { name: 'Profile', icon: 'bi bi-person-square' },
                { name: 'Ortu', icon: 'bi bi-person-vcard-fill' },
                { name: 'Info', icon: 'bi bi-info-circle-fill' },
                { name: 'Upload', icon: 'bi bi-cloud-upload-fill' },
                { name: 'Kartu', icon: 'bi bi-person-badge-fill' }
            ],
            selectedMenu: 0,
        };
    },
    //      UserId: "",
    //   LoginOK: '',
    //   TokenOK: 0,
    mounted() {
        document.title = "PMB Baiturrahman";

        this.$globalData.userId =
            localStorage.getItem("userid") === null
                ? ""
                : localStorage.getItem("userid");
    },
    methods: {
        logout() {
            this.$globalData.TokenOK = 0;
            this.$router.push({ path: "/" });
        },
        selectMenu(index) {
            this.selectedMenu = index;
            this.$globalData.SelectedMenu = index;
            console.log("Menu", this.selectedMenu)
        }
    }
}
</script>

<style scoped>
.pmb-container {
    padding: 20px;
    padding-bottom: 70px;
    /* Memberi ruang untuk menu di bawah */
}

h2 {
    margin-bottom: 20px;
}

.content {
    min-height: 300px;
    /* Simulasi konten */
}

/* Menu fixed di bagian bawah */
.menu-fixed-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    /* Agar menu berjajar */
    align-items: center;
    border-top: 0px solid #ccc;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    z-index: 1000;
    /* Pastikan menu di atas elemen lain */
}

.menu-item {
    flex: 1;
    text-align: center;
    padding: 1px;
    transition: background-color 0.3s ease;
}

.menu-icon {
    font-size: 24px;
    /* Ukuran icon */
    margin-bottom: 5px;
    color: #333;
}

.menu-item p {
    margin: 0;
    font-size: 12px;
    /* Ukuran teks menu */
}

/* Warna aktif saat menu dipilih */
.menu-item.active {
    background-color: #64686b;
    /* Warna biru saat aktif */
    color: white;
}

.menu-item.active .menu-icon {
    color: white;
}

.social-media-links {
    display: grid;
    grid-template-columns: repeat(3, 14px);
    gap: 5px;
    /* Jarak antar item */
    justify-items: center;
    align-items: center;
}

.social-media-container {
    text-align: center;
    margin-top: 5px;
}

/* Responsif untuk layar kecil */
@media (max-width: 600px) {
    .menu-item {
        font-size: 10px;
    }

    .menu-icon {
        font-size: 20px;
    }
}
</style>