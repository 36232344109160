import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/components/home.vue";
import Menu from "@/components/menu.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/menu/:id?",
    name: "Menu",
    component: Menu,
  },

  // {
  //    path: '/about',
  //    name: 'About',
  //    // route level code-splitting
  //    // this generates a separate chunk (about.[hash].js) for this route
  //    // which is lazy-loaded when the route is visited.
  //    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode: "hash",
  //	base: process.env.BASE_URL,
  // base: process.env.NODE_ENV === "development" ? "/" : "/inward/",
  routes,
});

export default router;
